import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/Global.css';
import logo from '../img/logo_01.gif';
import whatsapp from '../img/WEB_DATA/Whatsapp.png';
import whatsappHover from '../img/WEB_DATA/Whatsapp_02.png';
import facebook from '../img/WEB_DATA/Facebook.png';
import facebookHover from '../img/WEB_DATA/Facebook_02.png';
import X from '../img/WEB_DATA/X.png';
import XHover from '../img/WEB_DATA/X_02.png';
import youtube from '../img/WEB_DATA/YouTube.png';
import youtubeHover from '../img/WEB_DATA/YouTube_02.png';
import instagram from '../img/WEB_DATA/Instagram.png';
import instagramHover from '../img/WEB_DATA/Instagram_02.png';

const Header = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [hoveredIcon, setHoveredIcon] = useState(null);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <header className="header">
      <div className="header-row logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className="header-row links">
        <div className="hamburger" onClick={toggleDrawer}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
        
        <div className={`nav-link-drawer ${isDrawerOpen ? 'open' : ''}`}>
          <div className="nav-links">
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/about-us">About Us</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </div>
        </div>

        {/* Social Links */}
        <div className="follow-links">
          <p className='follow'>Follow Us</p>
          <div className="social-links">
            <img
              src={hoveredIcon === 'facebook' ? facebookHover : facebook}
              alt="Facebook"
              onMouseEnter={() => setHoveredIcon('facebook')}
              onMouseLeave={() => setHoveredIcon(null)}
            />
            <img
              src={hoveredIcon === 'instagram' ? instagramHover : instagram}
              alt="Instagram"
              onMouseEnter={() => setHoveredIcon('instagram')}
              onMouseLeave={() => setHoveredIcon(null)}
            />
            <img
              src={hoveredIcon === 'youtube' ? youtubeHover : youtube}
              alt="YouTube"
              onMouseEnter={() => setHoveredIcon('youtube')}
              onMouseLeave={() => setHoveredIcon(null)}
            />
            <img
              src={hoveredIcon === 'X' ? XHover : X}
              alt="X"
              onMouseEnter={() => setHoveredIcon('X')}
              onMouseLeave={() => setHoveredIcon(null)}
            />
            <img
              src={hoveredIcon === 'whatsapp' ? whatsappHover : whatsapp}
              alt="WhatsApp"
              onMouseEnter={() => setHoveredIcon('whatsapp')}
              onMouseLeave={() => setHoveredIcon(null)}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
