import React from 'react';
import '../css/Global.css';
import news from '../img/WEB_DATA/NEWS_01.png';
import photos from '../img/WEB_DATA/PHOTOS_01.png';
import videos from '../img/WEB_DATA/VIDEOS_01.png';
import interview from '../img/WEB_DATA/INTERVIEWS_01.png';
import feature from '../img/WEB_DATA/FEATURES_01.png';
import reviews from '../img/WEB_DATA/REVIEWS_01.png';

const Additional = () =>{
    return(
        <>
        <div className="additional">
            <div className="additonal-list">
                <div className="additional-card">
                    <img src={news} alt="" />
                    <p>NEWS</p>
                </div>
                <div className="additional-card">
                    <img src={photos} alt="" />
                    <p>PHOTOS</p>
                </div>
                <div className="additional-card">
                    <img src={videos} alt="" />
                    <p>VIDEOS</p>
                </div>
                <div className="additional-card">
                    <img src={interview} alt="" />
                    <p>INTERVIEWS</p>
                </div>
                <div className="additional-card">
                    <img src={feature} alt="" />
                    <p>FEATURES</p>
                </div>
                <div className="additional-card">
                    <img src={reviews} alt="" />
                    <p>REVIEWS</p>
                </div>
            </div>
        </div>
        </>
    );
};

export default Additional;